<template>
<basic-page :pagedata="{ attributes: {} }" :bannerimg="this.headerImage">
        <div class="container-beige intro container">
            <h3 class="occhiello occhiello-beige">
                {{ this.convertDate() }}
            </h3>
            <h2>
                {{ this.pageData.attributes.title }}
            </h2>
        </div>
        <div class="container description">
            <p  v-if="this.pageData && this.pageData.attributes && this.pageData.attributes.field_news_description"
                v-html="this.pageData.attributes.field_news_description.processed"></p>
            <router-link class="link-to-news" :to="(lang==defaultLang ? '' : ('/' + lang)) + '/news'">
                {{ this.lang=='it' ? 'Tutte le News' : 'All the News'}} 
                <img src="../../assets/icon-arrow.svg" alt="" />
            </router-link>
        </div>
    </basic-page>
</template>

<script>
import { fetchNodes } from '../../libs/drupalClient'
import { reWriteDate } from '../../libs/utils'
import BasicPage from '../BasicPage.vue'
export default {
    components: { BasicPage },
    name: 'news-details',
    props: ['prova'],
    data: () => {
        return {
            currentPageId: "667a0e98-45f9-40e4-9f2b-3256918a8a81",
            pageData: { attributes: { } },
            headerImage: "",
            objectid: ''
        }
    },
    methods: {
        convertDate() {
            if(this.pageData && this.pageData.attributes && this.pageData.attributes.field_news_date)
                return reWriteDate(this.pageData.attributes.field_news_date)
            else
                return ''
        },
        manageImages(t){
            //manage wysiwyg images
            let text = t
            let fixedUrl = text.replace("/sites/default/files/inline-images/", process.env.VUE_APP_ENDPOINT + "/sites/default/files/inline-images/")

            return fixedUrl
        },
    },
    computed: {
        newsNID() {
            return this.$route.params.nid
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    mounted() {
        //console.log('msg', this.prova)
        this.objectid = this.$route.params.nid

        fetchNodes('news', {
            filters: [{
                key: 'drupal_internal__nid',
                value: this.objectid
            }],
            include: ['field_news_image']
        }, this.lang).then(res => {
            this.pageData = res[0]
            this.headerImage = process.env.VUE_APP_ENDPOINT + this.pageData.field_news_image.attributes.uri.url
            //console.log(this.headerImage)

        })
        
    },
    watch: {
        objectid() {
            fetchNodes('news', {
                filters: [{
                    key: 'drupal_internal__nid',
                    value: this.objectid
                }],
                include: ['field_news_image']
            }, this.lang).then(res => {
                this.pageData = res[0]
                this.headerImage = process.env.VUE_APP_ENDPOINT + this.pageData.field_news_image.attributes.uri.url

                //this.$router.push((this.lang==this.defaultLang ? '' : ('/' + this.lang)) + '/news/' + this.newsNID + '/' + slugify(this.pageData.attributes.title))    
            })
        },
        lang() {
            //console.log(this.$route)
            fetchNodes('news', {
                filters: [{
                    key: 'drupal_internal__nid',
                    value: this.objectid
                }],
                include: ['field_news_image']
            }, this.lang).then(res => {
                this.pageData = res[0]
                this.headerImage = process.env.VUE_APP_ENDPOINT + this.pageData.field_news_image.attributes.uri.url
                
                //this.$router.push((this.lang==this.defaultLang ? '' : ('/' + this.lang)) + '/news/' + this.newsNID + '/' + slugify(this.pageData.attributes.title))    
                
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors";

   ::v-deep p + p{
        margin-top: 1rem;
    }

.description {
    max-width: 960px;
    padding: 4rem 1rem;
}

 
.domori-partners {
    h3 {
        color: #000;
        font-family: 'Poppins';
        font-size: 1em;
    }
    .title {
        justify-content: center;
        div {
            width: unset;
            h2 {
                width: 100%;
            };
        }
    }
}
p {
    ::v-deep img {
        width: 100%;
    }
}

</style>